.profile-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: var(--background-color);
  padding: 20px;
  color: var(--text-color);
}

.profile-header {
  padding: 20px;
  position: sticky;
  top: 0;
  background: #1a1a1a;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.profile-header h1 {
  margin: 0;
  font-size: 24px;
  color: var(--text-color);
  flex: 1;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
  padding: 8px;
}

.profile-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile-photo-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-initials {
  width: 100%;
  height: 100%;
  background-color: var(--accent-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.profile-info {
  text-align: center;
  color: var(--text-color);
  flex-shrink: 0;
}

.profile-actions {
  margin-top: 20px;
}

.groups-list {
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  max-height: 430px;
}

.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
}

.delete-group {
  color: #ff4444;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.2s ease;
}

.delete-group.admin {
  background-color: rgba(255, 68, 68, 0.1);
}

.delete-group:hover {
  color: #ff0000;
  background-color: rgba(255, 68, 68, 0.2);
}

.groups-header {
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--text-color);
}

.profile-footer {
  padding: 20px;
  position: sticky;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.custom-modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.custom-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirm-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.no-groups {
  text-align: center;
  color: var(--text-color);
  opacity: 0.7;
  margin: 20px 0;
}

.group-container {
  margin-bottom: 20px;
}

.group-members {
  margin-top: 8px;
  margin-left: 20px;
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  padding-left: 15px;
}

.member-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  margin-bottom: 6px;
}

.member-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.member-name {
  color: var(--text-color);
}

.member-role {
  font-size: 0.8em;
  color: #FFC107;
  background: rgba(255, 193, 7, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}

.delete-member {
  color: #ff4444;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
}

.delete-member:hover {
  color: #ff0000;
}

.delete-member:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.delete-account-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-account-button:hover {
  background-color: #cc0000;
}

.profile-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: space-between
}

.password-input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  box-sizing: border-box;
}

.auth-error {
  color: #ff4444;
  font-size: 14px;
  margin: 5px 0;
}

.groups-list::after {
  content: '';
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  height: 20px;
  pointer-events: none;
}

.dice-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.dice-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(15deg);
}

.dice-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

.dice-button:active .dice-icon {
  animation: wiggle 0.3s ease;
}

.group-controls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.admin-toggle {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.admin-toggle .form-check-label {
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
  user-select: none;
  min-width: 45px;
  text-align: left;
}

/* Style for inactive state */
.admin-toggle .form-check-input:not(:checked) + .form-check-label {
  color: rgba(108, 117, 125, 0.5);
}

/* Style for active state */
.admin-toggle .form-check-input:checked + .form-check-label {
  color: #2ecc71;
  text-shadow: 0 0 8px rgba(46, 204, 113, 0.4);
}

/* Custom toggle switch styling */
.admin-toggle .form-check-input {
  position: relative;
  width: 36px;
  height: 20px;
  margin-top: 0;
  background-image: none !important;
  background-color: rgba(108, 117, 125, 0.2);
  border-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-toggle .form-check-input:checked {
  background-color: #2ecc71;
  border-color: #2ecc71;
  box-shadow: 0 0 8px rgba(46, 204, 113, 0.4);
}

/* Toggle switch animation */
.admin-toggle .form-check-input:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  top: 1px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.admin-toggle .form-check-input:checked:before {
  transform: translateX(16px);
}

/* Hover effects */
.admin-toggle .form-check-input:not(:disabled):hover {
  opacity: 0.9;
}

/* Focus state */
.admin-toggle .form-check-input:focus {
  border-color: transparent;
  box-shadow: none;
}

.admin-toggle .form-check-input:checked:focus {
  box-shadow: 0 0 8px rgba(46, 204, 113, 0.4);
}
