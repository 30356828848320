@keyframes rollDice {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  25% {
    transform: rotate(180deg) translateY(-20px);
  }
  50% {
    transform: rotate(360deg) translateY(0);
  }
  75% {
    transform: rotate(540deg) translateY(-10px);
  }
  100% {
    transform: rotate(720deg) translateY(0);
  }
}

.dice-face {
  position: relative;
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
}

.dice-face.rolling {
  animation: rollDice 0.8s ease-out;
}

.dice-face.selected {
  background: var(--accent-color, #4CAF50);
}

/* Add a subtle hover effect */
.dice-face:hover:not(.rolling) {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dot {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #333;
  border-radius: 50%;
}

.dice-face.selected .dot {
  background: white;
}

/* Dot positions for each dice value */
/* One */
.dice-value-1 .dot:nth-child(1) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Two */
.dice-value-2 .dot:nth-child(1) { top: 25%; left: 25%; }
.dice-value-2 .dot:nth-child(2) { bottom: 25%; right: 25%; }

/* Three */
.dice-value-3 .dot:nth-child(1) { top: 25%; left: 25%; }
.dice-value-3 .dot:nth-child(2) { top: 50%; left: 50%; transform: translate(-50%, -50%); }
.dice-value-3 .dot:nth-child(3) { bottom: 25%; right: 25%; }

/* Four */
.dice-value-4 .dot:nth-child(1) { top: 25%; left: 25%; }
.dice-value-4 .dot:nth-child(2) { top: 25%; right: 25%; }
.dice-value-4 .dot:nth-child(3) { bottom: 25%; left: 25%; }
.dice-value-4 .dot:nth-child(4) { bottom: 25%; right: 25%; }

/* Five */
.dice-value-5 .dot:nth-child(1) { top: 25%; left: 25%; }
.dice-value-5 .dot:nth-child(2) { top: 25%; right: 25%; }
.dice-value-5 .dot:nth-child(3) { top: 50%; left: 50%; transform: translate(-50%, -50%); }
.dice-value-5 .dot:nth-child(4) { bottom: 25%; left: 25%; }
.dice-value-5 .dot:nth-child(5) { bottom: 25%; right: 25%; }

/* Six */
.dice-value-6 .dot:nth-child(1) { top: 20%; left: 25%; }
.dice-value-6 .dot:nth-child(2) { top: 20%; right: 25%; }
.dice-value-6 .dot:nth-child(3) { top: 50%; left: 25%; transform: translateY(-50%); }
.dice-value-6 .dot:nth-child(4) { top: 50%; right: 25%; transform: translateY(-50%); }
.dice-value-6 .dot:nth-child(5) { bottom: 20%; left: 25%; }
.dice-value-6 .dot:nth-child(6) { bottom: 20%; right: 25%; } 

/* Add this to your existing CSS */
.highlight-three {
  animation: pulse 1s infinite;
  box-shadow: 0 0 10px #ffd700;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 10px #ffd700;
  }
  50% {
    box-shadow: 0 0 20px #ffd700;
  }
  100% {
    box-shadow: 0 0 10px #ffd700;
  }
}

.fixed-width-button {
  width: 100px;  /* or whatever width you prefer */
  min-width: 100px;
}

.form-check {
  margin-bottom: 0;
}

.form-check-label {
  padding-left: 8px;
  font-size: 0.875rem;  /* Make text smaller */
  color: #6c757d;       /* Use a muted color */
  font-weight: normal;  /* Ensure normal font weight */
}

/* Optional: make the switch itself a bit smaller */
.form-switch .form-check-input {
  width: 32px;          /* Default is 40px */
  height: 18px;         /* Default is 20px */
  margin-top: 3px;      /* Adjust vertical alignment */
}
