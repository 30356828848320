.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  background: var(--background-color);
  color: var(--text-color);
}

.loginContainer {
  display: flex;
  height: 100vh;
}

.login {
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logout {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.trackerContainer {
  margin-top: 15px;
  margin-left: 15px;
}

.header {
  font-size: 1.5em;
  color: darkgray;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  margin-left: 10px;
}

.landing-logo {
  width: 100%;
}

.headerImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.counter-container {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: space-between;
  margin-left: 10px;
}

.group-chart-container {
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.group-select {
  flex: 1;
  margin-bottom: 0;
}

.dynamic-inputs-container {
  flex: 0 0 auto;
  margin-top: 20px;
}

.input-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.item-select {
  flex: 0 0 auto;
  width: 200px;
  max-width: 200px;
}

.item-select .select__control {
  height: 42px;
}

.item-select .select__single-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}

.number-input {
  flex: 0 0 auto;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  height: 42px;
}

.add-button {
  flex: 0 0 42px;
  height: 42px;
  width: 42px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Custom styles for react-select */
.group-select .select__control,
.item-select .select__control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.group-select .select__control:hover,
.item-select .select__control:hover {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.15);
}

.group-select .select__menu,
.item-select .select__menu {
  background-color: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}

.group-select .select__option,
.item-select .select__option {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.group-select .select__option:hover,
.item-select .select__option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.group-select .select__option--is-selected,
.item-select .select__option--is-selected {
  background-color: rgba(255, 255, 255, 0.2);
}

.group-select .select__single-value,
.item-select .select__single-value {
  color: white;
}

.group-select .select__placeholder,
.item-select .select__placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.group-select .select__indicator-separator,
.item-select .select__indicator-separator {
  background-color: rgba(255, 255, 255, 0.2);
}

.group-select .select__dropdown-indicator svg,
.item-select .select__dropdown-indicator svg {
  fill: rgba(255, 255, 255, 0.5);
}

/* Input styling */
.number-input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  flex: 1;
  transition: all 0.2s ease;
}

.number-input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.15);
}

.number-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Add button styling */
.add-button {
  background-color: #177AD5;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 50px;
}

.add-button:hover:not(:disabled) {
  background-color: #1488ee;
  transform: translateY(-1px);
}

.add-button:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

/* Remove default number input arrows */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input[type=number] {
  -moz-appearance: textfield;
}

.no-groups-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.here-link {
  background: none;
  border: none;
  color: var(--accent-color);
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.here-link:hover {
  opacity: 0.8;
}

.headerInitials {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4a90e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}

.user-summary {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  text-align: center;
}

.user-summary h3 {
  margin: 0 0 8px 0;
  font-size: 0.9rem;
  color: #FFC107;
}

.values-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.value-item {
  display: flex;
  gap: 6px;
  align-items: center;
}

.item-name {
  font-weight: 500;
  color: #fff;
}

.item-value {
  color: #FFC107;
  font-weight: bold;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.group-chart-container {
  min-height: 200px;
}

/* Make the chart container take available space */
.chart-container {
  flex: 1;
  min-height: 0;
  width: 100%;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 15px;
}

/* Ensure the chart canvas maintains aspect ratio */
canvas {
  max-height: 100%;
  width: 100% !important;
  height: 100% !important;
  color: white !important;
}

/* Add new styles for the group header container */
.group-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Move the add button styles from the bottom of the page to be inline */
.group-add-button {
  background-color: #177AD5;
  color: white;
  border: none;
  border-radius: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 24px;
}

.group-add-button:hover {
  background-color: #1488ee;
  transform: translateY(-1px);
}

.header-photo-container {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closed-group-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin-top: auto;  /* Push to bottom */
  color: #6c757d;
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.input-controls {
  display: flex;
  gap: 8px;
  margin-top: auto;  /* Push to bottom */
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  padding: 5px;
}

.group-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* New styles for the controls container */
.controls-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-color);
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 100;
}

.closed-group-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  color: #6c757d;
  font-size: 0.9rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.input-controls {
  display: flex;
  gap: 8px;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.tabs {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.tab {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.7;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
}

.tab:hover {
  opacity: 1;
}

.tab.active {
  opacity: 1;
  border-bottom: 2px solid var(--text-color);
}
