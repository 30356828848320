.logout-button {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  width: 30%;
}

.logout-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.logout-button:active {
  transform: scale(0.98);
}

.logout-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
} 
