.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-content {
  background: var(--background-primary);
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.custom-modal-content h3 {
  margin: 0 0 15px 0;
  color: var(--text-color);
}

.custom-modal-content .location-input {
  margin: 0 0 15px 0;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-primary);
  color: var(--text-color);
  font-size: 14px;
}

.custom-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-button {
  padding: 8px 16px;
  background: var(--background-secondary);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

/* Google Places Autocomplete customization */
.pac-container {
  z-index: 10000 !important;
  background-color: #000000 !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  font-family: inherit;
  position: fixed !important;
  width: calc(90% - 40px) !important;
  max-width: 360px !important;
}

.pac-item {
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  background-color: #000000 !important;
  border-color: var(--border-color) !important;
}

.pac-item:hover {
  background-color: #1a1a1a !important;
}

.pac-item-query {
  color: var(--text-color);
}

.pac-matched {
  color: var(--text-color);
  font-weight: bold;
}

.pac-secondary-text {
  color: var(--text-secondary);
} 
