.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal-content {
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    135deg,
    rgba(40, 40, 40, 0.95),
    rgba(20, 20, 20, 0.95)
  );
  animation: slideUp 0.3s ease;
  transform-origin: center;
  position: relative;
  min-height: 200px;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transform: rotate(90deg);
}

.tab-container {
  display: flex;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  gap: 10px;
  padding: 0 10px;
}

.tab {
  flex: 1;
  padding: 12px 20px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 16px;
  position: relative;
  transition: all 0.3s ease;
}

.tab.active {
  color: white;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1DA1F2;
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from { transform: scaleX(0); }
  to { transform: scaleX(1); }
}

.submit-button {
  width: 100%;
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
}

.submit-button:not(.disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 161, 242, 0.3);
}

.submit-button:not(.disabled):active {
  transform: translateY(0);
}

.submit-button.disabled {
  background: linear-gradient(45deg, #3a3a3a, #2a2a2a);
  cursor: not-allowed;
  position: relative;
  opacity: 0.8;
  transform: none;
  box-shadow: none;
}

.submit-button:disabled:not(.disabled) {
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  cursor: not-allowed;
}

.submit-button:disabled:not(.disabled)::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

.button-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 1000;
}

.button-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
}

.submit-button.disabled:hover .button-tooltip {
  opacity: 1;
}

.button-text {
  position: relative;
  z-index: 1;
}

@keyframes loading {
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}

.create-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Enhanced styles for the found group section */
.found-group {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  animation: fadeIn 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.found-group h3 {
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
}

.found-group .group-name {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: white;
}

.found-group .member-count {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  font-size: 14px;
}

.found-group:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.07);
}

.search-container {
  display: flex;
  gap: 12px;
}

.join-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 15px;
  transition: all 0.3s ease;
}

.join-input:focus {
  border-color: #1DA1F2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.2);
}

.search-button {
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.search-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 161, 242, 0.3);
}

.search-button:disabled {
  position: relative;
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  cursor: not-allowed;
}

.search-button:disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

.join-button {
  width: 100%;
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.join-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 161, 242, 0.3);
}

.join-button:not(:disabled):active {
  transform: translateY(0);
}

.join-button:disabled {
  position: relative;
  background: linear-gradient(45deg, #1DA1F2, #1a91da);
  cursor: not-allowed;
}

.join-button:disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.success-icon {
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 16px;
}

.loading-spinner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.modal-content {
  position: relative;
  min-height: 200px;
}

.close-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
