.unique-group-input {
  padding: 20px;
}

.input-field {
  position: relative;
  margin-bottom: 20px;
}

.group-name-input,
.item-input {
  width: 100%;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
}

.group-name-input:focus,
.item-input:focus {
  outline: none;
  border-color: #177AD5;
  box-shadow: 0 0 0 2px rgba(23, 122, 213, 0.2);
}

.invalid {
  border-color: #ff4444;
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.items-list {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item-tag {
  display: flex;
  align-items: center;
  background: rgba(23, 122, 213, 0.1);
  border: 1px solid rgba(23, 122, 213, 0.2);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: white;
}

.item-tag .remove-item {
  background: none;
  border: none;
  color: #ff4444;
  margin-left: 8px;
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}

.item-input-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.item-input {
  flex: 1;
}

.add-item-button {
  background: #177AD5;
  color: white;
  border: none;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.add-item-button:disabled {
  background: #666;
  cursor: not-allowed;
}

.add-item-button:hover:not(:disabled) {
  background: #1468b3;
} 

.info-message {
  color: #666;
  font-size: 0.8rem;
  margin-top: 5px;
  display: block;
}

.item-input-container input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.add-item-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.group-name-input:disabled,
.item-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.remove-item:disabled,
.add-item-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 
