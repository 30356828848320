.loading-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: var(--background-color);
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(18, 18, 18, 0.7); /* Using a dark overlay that matches the app theme */
  z-index: 9999;
} 
