.feed-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: calc(100vh - 190px); /* Adjust based on header + tabs height */
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}

.feed-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.post-form {
  position: relative;
  background: var(--background-primary);
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.posts-container {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.post-input {
  width: 100%;
  min-height: 60px;
  max-height: 120px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-secondary);
  color: var(--text-color);
  resize: vertical;
  font-size: 16px;
}

.post-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.post-actions-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.post-actions-right {
  display: flex;
  gap: 10px;
  align-items: center;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.file-input-button {
  background: var(--background-secondary);
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
}

.file-input-button:hover {
  background: var(--background-primary);
  border-color: var(--text-color);
}

.post-button {
  padding: 8px 20px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.post-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.post-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.post {
  background: var(--background-secondary);
  padding: 15px;
  border-radius: 8px;
  width: 100%;
}

.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.post-header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  font-weight: 500;
  color: var(--text-color);
  font-size: 14px;
}

.post-image-container {
  margin: 10px 0;
  width: 100%;
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  object-fit: contain;
}

.post-text {
  margin: 10px 0;
  color: var(--text-color);
  white-space: pre-wrap;
  font-size: 14px;
}

.post-timestamp {
  font-size: 13px;
  color: var(--text-secondary);
  margin-top: 8px;
}

.delete-post-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.delete-post-button:hover {
  opacity: 1;
  color: var(--text-color);
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .feed-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }

  .post-actions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .post-button {
    width: auto;
  }

  .file-input {
    width: auto;
  }
}

.loading-more {
  text-align: center;
  padding: 20px;
  color: var(--text-secondary);
  font-size: 14px;
}

.no-more-posts {
  text-align: center;
  padding: 20px;
  color: var(--text-secondary);
  font-size: 14px;
  border-top: 1px solid var(--border-color);
  margin-top: 20px;
}

.posts-container::-webkit-scrollbar {
  width: 8px;
}

.posts-container::-webkit-scrollbar-track {
  background: var(--background-primary);
}

.posts-container::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

.posts-container::-webkit-scrollbar-thumb:hover {
  background: rgb(113, 118, 123);
}

.char-count {
  font-size: 13px;
  color: var(--text-secondary);
  margin-left: auto;
  margin-right: 10px;
}

:root {
  --text-secondary: rgb(113, 118, 123);
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-content {
  background: var(--background-primary);
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.custom-modal-content h3 {
  margin: 0 0 15px 0;
  color: var(--text-color);
}

.custom-modal-content .location-input {
  margin: 0 0 15px 0;
  width: 100%;
}

.custom-modal-content p {
  margin: 0 0 20px 0;
  color: var(--text-secondary);
}

.custom-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.confirm-button {
  padding: 8px 16px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  padding: 8px 16px;
  background: var(--background-secondary);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.location-button {
  background: var(--background-secondary);
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
}

.location-button:hover {
  background: var(--background-primary);
  border-color: var(--text-color);
}

.location-input {
  margin-top: 10px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-primary);
  color: var(--text-color);
  font-size: 14px;
}

.location-input::placeholder {
  color: var(--text-secondary);
}

.post-location {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: color 0.2s ease;
}

.post-location:hover {
  color: var(--text-color);
  text-decoration: underline;
}

/* Google Places Autocomplete customization */
.pac-container {
  z-index: 10000 !important;
  background-color: var(--background-primary) !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  font-family: inherit;
  position: fixed !important;
  width: calc(90% - 40px) !important;
  max-width: 360px !important;
}

.pac-item {
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  background-color: var(--background-primary) !important;
  border-color: var(--border-color) !important;
}

.pac-item:hover {
  background-color: var(--background-secondary) !important;
}

.pac-item-query {
  color: var(--text-color);
}

.pac-matched {
  color: var(--text-color);
  font-weight: bold;
}

.pac-secondary-text {
  color: var(--text-secondary);
}

.post-form-footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.post-attachments {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.selected-file,
.selected-location {
  color: var(--text-secondary);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.post-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 4px;
}

.file-input-button,
.location-button {
  background: var(--background-secondary);
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.file-input-button:hover,
.location-button:hover {
  background: var(--background-primary);
  border-color: var(--text-color);
}

.location-input {
  margin-top: 8px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-primary);
  color: var(--text-color);
  font-size: 14px;
} 
