.googleLogout {
  margin-top: 10px;
  margin-right: 10px;
}

#signOutButton {
  display: flex;
  flex-direction: row-reverse;
}

.logoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.logoutContainer h2 {
  color: #333;
  font-size: 1.5rem;
  text-align: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-logo {
  width: 150px;
  margin-bottom: 2rem;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #3c4043;
  cursor: pointer;
  transition: background-color 0.2s;
}

.google-signin-button:hover {
  background-color: #f8f9fa;
}

.google-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.error-message {
  color: #d93025;
  margin: 1rem 0;
  padding: 8px;
  background-color: #fce8e6;
  border-radius: 4px;
  font-size: 14px;
}
